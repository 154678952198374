import { RouteConstants } from '../../config/route.constants';
import { Component } from '@angular/core';
import { NguCarouselConfig } from '@ngu/carousel';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent {
    carouselTile: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        slide: 1,
        speed: 1100,
        point: {
            visible: true,
        },
        load: 3,
        velocity: 0,
        interval: {
            timing: 4000,
        },
        loop: true,
        touch: true,
        easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };

    data = [
        {
            imgUrl: 'assets/images/carousel/bg-1.webp',
            title: 'Stop Cyberattacks. Protect Your Data, Users, and Applications'
        },
        {
            imgUrl: 'assets/images/carousel/bg-2.webp',
            title: 'Next-Gen Security: SD-WAN, ZTNA, SWG, NGFW - All in One Platform'
        },
        {
            imgUrl: 'assets/images/carousel/bg-3.webp',
            title: 'Predictive Security: Advanced threat detection and Response with Cyber resiliency simplified'
        },
        {
            imgUrl: 'assets/images/carousel/bg-4.webp',
            title: 'Secure Access, Everywhere. Work From Anywhere with Smart SASE'
        }
    ]
    carouselSliderList = [
        {
            id: 2,
            title: 'Secure Access for Branch Offices,<br> Teleworkers and IOT/OT',
            subtitle: 'Seamless and Secure remote ZTNA',
            // logoURL: 'https://cdn.cosgrid.com/website/assets/MZA/Carousel/reflex_darkmode_2.webp',
            logoURL: 'https://cdn.cosgrid.com/website/assets/MZA/Carousel/microzaccess_darkmode_2.webp',
            imgURL: 'assets/images/carousel/Banner-1.png',
            // imgURL: 'https://cdn.cosgrid.com/website/assets/carousel/carousel-img-01.png',
            contact: 'https://cosgrid.net',
            // gradient: 'linear-gradient(315deg, #1565c0, #1976d2, #1e88e5, #2196f3, #42a5f5)',
            gradient: 'linear-gradient(315deg,rgb(41, 6, 87), rgb(41, 6, 87))',
            video: false,
            demo: false,
        },
        {
            id: 1,
            title: 'Superior connectivity uptime and <br> application performance ',
            subtitle: 'Enabled by Multi Radio 4G/5G SD-WAN/ NGFW',
            logoURL: 'https://cdn.cosgrid.com/website/assets/MZA/Carousel/reflex_darkmode_2.webp',
            // imgURL: 'https://cdn.cosgrid.com/website/assets/carousel/connectivity.png',
            imgURL: 'assets/images/carousel/Banner-2.png',
            contact: 'https://cosgrid.net',
            gradient: 'linear-gradient(225deg, #4cc9f0, #4895ef, #4361ee, #3f37c9, #3a0ca3)',
            video: false,
            demo: false,
        },
        {
            id: 4,
            title: 'Seamless Manageability and <br> Visibility with NetSecOps',
            subtitle: 'Digital Experience and User Experience Management',
            logoURL: 'assets/images/home/sase-white.png',
            // imgURL: 'https://cdn.cosgrid.com/website/assets/carousel/carosuel 4 visiblity.png',
            imgURL: 'assets/images/carousel/Banner-3.png',
            contact: 'https://cosgrid.com/contacts',
            gradient: 'linear-gradient(315deg,rgb(41, 6, 87), rgb(41, 6, 87))',
            video: false,
            demo: false,
        },

        {
            id: 3,
            title: 'Reduced Risk using <br> Zero Trust Access',
            subtitle: 'Experience Next Gen Zero Trust Access in Minutes',
            logoURL: 'https://cdn.cosgrid.com/website/assets/MZA/Carousel/microzaccess_darkmode_2.webp',
            imgURL: 'assets/images/carousel/Banner-4.png',
            // imgURL: 'https://cdn.cosgrid.com/website/assets/carousel/carousel-img-03.png',
            contact: 'https://cosgrid.com/contacts',
            // gradient: 'linear-gradient(225deg, #45108A, #3D065F , #10054D)',
            gradient: 'linear-gradient(315deg, #1565c0, #1976d2, #1e88e5, #2196f3, #42a5f5)',

            // #90e0ef, #48cae4, #00b4d8, #0096c7, #0077b6
            video: false,
            demo: false,
        },
    ];


    aboutCosGrid = [
        {
            title: 'Zero Trust Access with Ubiquitous & Reliable Connectivity',
            image: 'https://cdn.cosgrid.com/website/assets3/Home/Zero%20Trust%20and%20Zero%20Glitch%20Network%20Access.webp',
        },
        {
            title: 'Holistic Security & Advanced Threat Protection',
            image: 'https://cdn.cosgrid.com/website/assets3/Home/Converged%20Security%20and%20Orchestration.webp',
        },
        {
            title: 'Deep Network Visibility & Seamless Security Orchestration',
            image: 'https://cdn.cosgrid.com/website/assets3/Home/Enhanced%20UX%20and%20Easy%20NetSecOps.webp',
        },
    ];

    backgroundUrl = 'https://www.gettyimages.ie/gi-resources/images/Homepage/Hero/UK/CMS_Creative_164657191_Kingfisher.jpg'

    constructor() { }
}
