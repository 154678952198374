
import { RouteConstants } from '../../config/route.constants';
import { NguCarouselConfig } from '@ngu/carousel';
import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { USECASES } from 'src/app/data/usecases';
import { Router } from '@angular/router';
import { MyEmitterService } from 'src/app/app-emitter.service';
import BLOG_QUERY from 'src/app/queries/blogs/blogs.service';
import { Apollo } from 'apollo-angular';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { Title, Meta, DomSanitizer } from '@angular/platform-browser';
import { FetchMetaService } from '../../queries/Meta/fetchmeta.service';
import { Subject, Subscription } from 'rxjs';
import META_QUERY from '../../queries/Meta/meta.service';
import { IAwardItem, IAssocicationItem } from 'src/app/shared/association/association.component';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>()
    private queryBlog: Subscription;
    blogs: any;

    carouselTile: NguCarouselConfig = {
        grid: { xs: 2, sm: 2, md: 4, lg: 4, all: 0 },
        slide: 1,
        speed: 1000,
        point: {
            visible: true,
        },
        load: 4,
        velocity: 0,
        interval: {
            timing: 2000,
        },
        loop: true,
        touch: false,
        easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };

    carouselVideoTile: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 3, lg: 3, all: 0 },
        slide: 1,
        point: {
            visible: true,
        },
        load: 4,
        velocity: 0,
        loop: false,
        touch: false,
        easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };

    about = [
        {
            imgUrl: 'https://cdn.cosgrid.com/website/assets3/Home/Zero%20Trust%20and%20Zero%20Glitch%20Network%20Access.webp',
            desc: '<span class="clr-blue-dark fw-bold">Zero Trust Access</span> with Ubiquitous & <span class="clr-blue-dark fw-bold">Reliable Connectivity</span>',
            alt: '',
            class: 'h-70'
        },
        {
            desc: '<span class="clr-blue-dark fw-bold">Holistic Security</span> & Advanced <span class="clr-blue-dark fw-bold">Threat Protection</span>',
            imgUrl: 'https://cdn.cosgrid.com/website/assets3/Home/Converged%20Security%20and%20Orchestration.webp',
            alt: '',
            class: 'h-70'
        },
        {
            desc: 'Deep <span class="clr-blue-dark fw-bold">Network Visibility</span> & Seamless <span class="clr-blue-dark fw-bold">Security Orchestration</span>',
            imgUrl: 'https://cdn.cosgrid.com/website/assets3/Home/Enhanced%20UX%20and%20Easy%20NetSecOps.webp',
            alt: '',
            class: 'h-70'
        }
    ]

    awards: IAwardItem[] = [
        {
            title: 'Patent on Adaptive multi-path traffic <br/> SD-WAN',
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Awards/Patent%20on%20Adaptive%20multi-path%20traffic%20SD-WAN.webp',
            width: 'w-30',
        },
        {
            title: 'Cybersecurity Grand Challenge Winner - DSCI & MeiTy',
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Awards/Top%205%20Finalist%20&%20Second%20Stage%20Winner.webp',
            width: 'w-30',
        },
        {
            title: 'Winner TANSEED 2.0 - Startup TN & <br/> Govt of Tamil Nadu',
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Awards/TANSEED%202.0.webp',
            width: 'w-30',
        },
        {
            title: "India's first 5G SD-WAN - Tested at 5G Test Bed - IITM",
            imgURL: "https://cdn.cosgrid.com/website/assets3/Home/Awards/India's%20first%205G%20SD-WAN%20-%20Tested%20at%205G%20Test%20Bed%20-%20IITM.webp",
            width: 'w-30',
        },
        {
            title: 'Make in India Hardware -<br> DOT, GOI',
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Awards/Make%20in%20India%20Hardware%20-%20DOT,%20GOI.png',
            width: 'w-40',
        },
        {
            title: 'Leading cybersecurity and networking solution providers on ZTNA',
            imgURL: 'assets/images/fast-mode.png',
            width: 'w-30',
        },
    ];

    ourProducts = [
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.ZTNA}/microzaccess`,
            imgUrl: 'assets/images/home/MicroZAccess_1.png',
            name: 'ZTNA -Zero Trust Network Access',
            width: 'w-50'
        },
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
            imgUrl: 'https://cdn.cosgrid.com/website/assets3/Products/SASE/2.webp',
            name: 'SASE- Secure Access Service Edge',
            width: 'w-50'
        },
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SASE}`,
            imgUrl: 'assets/images/home/our-products-sig.webp',
            fragment: 'sig',
            name: 'SWG- Secure Web Gateway',
            width: 'w-50'
        },
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.SD_WAN}`,
            imgUrl: 'https://cdn.cosgrid.com/website/assets3/Products/SDWANv1/1.webp',
            name: 'Secure & Reliable Connectivity SD-WAN',
            width: 'w-50'
        },
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.FIREWALL}`,
            imgUrl: 'https://cdn.cosgrid.com/website/assets3/Products/Firewall/firewall%20logo%20cgrid.webp',
            name: 'NGFW - Next Generation Firewall',
            width: 'w-50'
        },
        {
            link: `/${RouteConstants.PRODUCTS}/${RouteConstants.NETSHIELD}`,
            imgUrl: 'https://cdn.cosgrid.com/website/assets3/Products/NDR/NS.svg',
            name: 'NDR- Network Detection and Response',
            width: 'w-50'
        }
    ]

    customerSliderList = [
        {
            id: 1,
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/The India Cements.webp',
            height: '',
            class: 'w-50',
            alt: 'Indian Cements',
        },
        {
            id: 2,
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/Orange Retail Finance.webp',
            height: '',
            class: 'w-50',
            alt: 'Orange Retail Finance',
        },
        {
            id: 3,
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/zimson-logo.avif',
            height: '',
            class: 'w-50',
            alt: 'Zimson Watches',
        },
        {
            id: 4,
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/Sankara%20Nethralaya.jpg',
            height: '',
            class: 'w-50',
            alt: 'Sankara Nethralaya',
        },
        {
            id: 5,
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/Qube.webp',
            height: '',
            class: 'w-50',
            alt: 'Qube',
        },
        {
            id: 8,
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/codemantra.webp',
            height: '',
            class: 'w-50',
            alt: 'codemantra',
        },
        {
            id: 7,
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/impiger technologies.jpg',
            height: '',
            class: 'w-50',
            alt: 'impiger technologies',
        },

        {
            id: 6,
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/RSC.webp',
            height: '',
            class: 'w-50',
            alt: 'RSC',
        },

        {
            id: 10,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images1/Coral-Logo-700x464-1.png',
            height: '',
            class: 'w-50',
            alt: 'Coral',
        },
        {
            id: 11,
            imgURL: 'https://cdn.cosgrid.com/website/assets/images1/signaltron.png',
            height: '',
            class: 'w-50',
            alt: 'Signaltron',
        },
        {
            id: 12,
            imgURL: 'assets/images/home/ntc-logistic.png',
            height: '',
            class: 'w-30',
            alt: 'NTC Logistic',
        },

        {
            id: 9,
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/Reliance Communications.webp',
            height: '',
            class: 'w-50',
            alt: 'Reliance Communications',
        },
    ];

    Integration: any[] = [
        {
            imgURL: 'https://cdn.cosgrid.com/website/assets/gsuite-logo.jpg',
            name: 'GSuite Logo',
            width: '60%',
            link: 'https://docs.cosgrid.com/doc/66?productId=4&topic=Authentication%20%26%20Identity%20Management%20(IAM)&subTopic=66&logo=',
        },
        {
            imgURL: 'https://cdn.cosgrid.com/website/assets/okta-logo.jpg',
            name: 'Okta Logo',
            width: '50%',
            link: 'https://docs.cosgrid.com/doc/63?productId=4&topic=Authentication%20%26%20Identity%20Management%20(IAM)&subTopic=63&logo=',
        },
        {
            imgURL: 'https://cdn.cosgrid.com/website/assets/azure-logo.png',
            name: 'Azure Logo',
            width: '80%',
            link: 'https://docs.cosgrid.com/doc/62?productId=4&topic=Authentication%20%26%20Identity%20Management%20(IAM)&subTopic=62&logo=',
        },
        {
            imgURL: 'https://cdn.cosgrid.com/website/assets/onelogin.png',
            name: 'One Login ',
            width: '60%',
            link: 'https://docs.cosgrid.com/doc/67?productId=4&topic=Authentication%20%26%20Identity%20Management%20(IAM)&subTopic=67&logo=',
        },
    ];

    associateWith: any[] = [
        {
            // title: 'Department of Telecommunications',
            imgURL: 'https://cdn.cosgrid.com/website/assets/images1/associations/Department of Telecommunications.png',
            alt: 'Department of Telecommunications',
            class: 'w-70',
        },
        {
            // title: 'TCOE',
            imgURL: 'https://cdn.cosgrid.com/website/assets/images1/associations/tcoe.png',
            alt: 'tcoe',
            class: 'w-40',
        },
        {
            // title: 'TEPC',
            imgURL: 'https://cdn.cosgrid.com/website/assets/images1/associations/tepc.png',
            alt: 'tepc',
            class: 'w-20',
        },
        {
            // title: 'iit',
            imgURL: 'https://cdn.cosgrid.com/website/assets/images1/associations/iit.png',
            alt: 'iit',
            class: 'w-80',
        },
        {
            // title: 'Microsoft',
            // imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/microsoftImage.png',
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/Microsoft.webp',
            alt: 'Microsoft',
            class: 'w-50',
        },
        {
            // title: 'Intel',
            // imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/intelImage.png',
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/intel.webp',
            alt: 'intel',
            class: 'w-40',
        },
        {
            // title: 'Google Cloud',
            imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/Google-cloud.png',
            alt: 'Google-cloud',
            class: 'w-70',
        },
        {
            imgURL: 'assets/images/home/voice.webp',
            alt: 'voice',
            class: 'w-40',
        },
        {
            imgURL: 'assets/images/home/n-coe-logo.webp',
            alt: 'n-coe-logo',
            class: 'w-40',
        },
    ];

    videos: any[] = [
        {
            srcdoc: this.generateYoutubeSrcDoc('PUu4_-0eXSc'),
            url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/PUu4_-0eXSc'),
            title: 'COSGrid MicroZAccess - An Uncomplicated Zero Trust Network Access - Product Overview Demo',
        },
        {
            srcdoc: this.generateYoutubeSrcDoc('CXwhA86gEsM'),
            url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/CXwhA86gEsM'),
            title: 'Zero Trust Access to Cloud Application and Multi - cloud Infra using MicroZAccess - Demo Use case',
        },
        {
            srcdoc: this.generateYoutubeSrcDoc('42b0T7EwXkE'),
            url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/42b0T7EwXkE'),
            title: 'Zero Trust Security Insights #2 - ZTNA Product Considerations',
        },
        {
            srcdoc: this.generateYoutubeSrcDoc('CrzO3zA5qls'),
            url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/CrzO3zA5qls'),
            title: 'Zero Trust Security Insights #1 - Invisible the Visible',
        },
        {
            srcdoc: this.generateYoutubeSrcDoc('90BqQRpM8qo'),

            url: this.sanitizeYoutubeUrl('https://www.youtube.com/embed/90BqQRpM8qo'),
            title: 'COSGrid MicroZAccess - Concept Video - ZTNA',
        },
    ];

    whyEnterprisesChooseCosgrid = {
        imageRoute: 'https://cdn.cosgrid.com/website/assets/home/why-choose-cosgrid/',
        data: [
            {
                image: 'innovation',
                title: '',
                desc: '<span class="clr-blue-dark fw-bold">Technological Innovations</span> like Patented Multi-Path Traffic Aggregation, Mesh VPN, NetSecOps in a single dashboard. ',
            },
            {
                image: 'dashboard',
                title: '',
                desc: '<span class="clr-blue-dark fw-bold">Leading NetSecOps</span> solution that enables Secure Network Access, Customer experience, and visibility in a <span class="clr-blue-dark fw-bold">single window.</span>',
            },
            {
                image: 'integration',
                title: '',
                desc: '<span class="clr-blue-dark fw-bold">Integrated Network Security</span> & SD-WAN platform for <span class="clr-blue-dark fw-bold">remote branch offices, WFH users, IoT & cloud access.</span>',
            },
            {
                image: 'spy',
                title: '',
                desc: '<span class="clr-blue-dark fw-bold">3x more effective in threat hunting</span> through innovations in network anomaly detection.',
            },
        ],
    }

    sanitizeYoutubeUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    generateYoutubeSrcDoc(url: string) {
        return this.sanitizer.bypassSecurityTrustHtml(
            `<style>*{padding:0;margin:0;overflow:hidden}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/${url}?autoplay=1><img src=https://img.youtube.com/vi/${url}/hqdefault.jpg loading="lazy"><span>▶</span></a>`,
        );
    }

    blogdata = [
        {
            id: 7,
            readtime: '10 mins read',
            background: '#0468BF',
            color: 'white',
            date: '',
        },
        {
            id: 9,
            readtime: '8 mins read',
            background: '#F2B705',
            color: 'black',
            date: '',
        },
        {
            id: 8,
            readtime: '5 mins read',
            background: '#EF8354',
            color: 'black',
            date: '',
        },
        {
            id: 6,
            readtime: '8 mins read',
            background: '#E43A3A',
            color: 'white',
            date: '',
        },
        {
            id: 5,
            readtime: '10 mins read',
            background: '#0468BF',
            color: 'white',
            date: '',
        },
        {
            id: 4,
            readtime: '5 mins read',
            background: '#F2B705',
            color: 'black',
            date: '',
        },
    ];






















    // INDUSTRIES_ROUTE = `/${RouteConstants.INDUSTRIES}/.`;
    // PRIVACY_POLICY = '';
    // aboutCosGrid = [
    //     {
    //         title: '<strong class="clr-blue">Zero Trust Access</strong> with Ubiquitous & <strong class="clr-blue">Reliable Connectivity</strong>',
    //         image: 'https://cdn.cosgrid.com/website/assets3/Home/Zero%20Trust%20and%20Zero%20Glitch%20Network%20Access.webp',
    //     },
    //     {
    //         title: '<strong class="clr-blue">Holistic Security</strong> & Advanced <strong class="clr-blue">Threat Protection</strong>',
    //         image: 'https://cdn.cosgrid.com/website/assets3/Home/Converged%20Security%20and%20Orchestration.webp',
    //     },
    //     {
    //         title: 'Deep <strong class="clr-blue">Network Visibility</strong> & Seamless <strong class="clr-blue">Security Orchestration</strong>',
    //         image: 'https://cdn.cosgrid.com/website/assets3/Home/Enhanced%20UX%20and%20Easy%20NetSecOps.webp',
    //     },
    // ];
    // introImage = 'https://cdn.cosgrid.com/website/assets3/Home/Customers/About COSGrid .gif';
    // intro = [
    //     {
    //         title: 'Zero Trust and <br />Zero Glitch Network Access',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/blog_images/aboutus-intro-01.webp',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/blog_images/zt.png',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Zero%20Trust%20and%20Zero%20Glitch%20Network%20Access.webp',
    //         width: '36%',
    //         height: '25%',
    //         alt: 'Zero Trust and Zero Glitch Network Access',
    //     },
    //     {
    //         title: 'Converged Security and <br />Orchestration',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/blog_images/aboutus-intro-02.webp',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Converged%20Security%20and%20Orchestration.webp',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/images/aboutus/aboutus-intro-02.png',
    //         width: '40%',
    //         height: '50%',
    //         alt: 'Converged Security and Orchestration',
    //     },
    //     {
    //         title: 'Enhanced UX and <br />Easy NetSecOps',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Enhanced%20UX%20and%20Easy%20NetSecOps.webp',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/blog_images/aboutus-intro-03.webp',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/images/aboutus/aboutus-intro-03.png',
    //         width: '44%',
    //         height: '50%',
    //         alt: 'Enhanced UX and Easy NetSecOps',
    //     },
    // ];
    // features = {
    //     imageRoute: 'https://cdn.cosgrid.com/website/assets/home/cosgrid-features/',
    //     data: [
    //         {
    //             title: 'Ubiqutous Connectivity with SLA',
    //             image: 'global-network',
    //             points: ['Multi-radio 4G/5G and Broadband', 'Zero touch and Anywhere Connectivity'],
    //         },
    //         {
    //             title: 'Zero Glitch Experience for Apps',

    //             image: 'data-transfer',
    //             points: ['Multi-WAN Link Aggregation', 'App based Intelligent traffic Steering'],
    //         },
    //         {
    //             title: 'Seamless Cloud and on-Prem SASE',

    //             image: 'secure-data',
    //             points: ['Zero Trust Network Access', 'Threat Protection with NGFW'],
    //         },
    //         {
    //             title: 'Deeper Visibilty and Management ',
    //             image: 'computer',
    //             points: ['Simplified Orchestration', 'User experience Monitoring'],
    //         },
    //     ],
    // };



    // whyEnterprisesChooseCosgrid = [
    //     {
    //         title: '<b>Technological Innovations</b> like Patented Multi-Path Traffic Aggregation , Mesh VPN , NetSecOps in a single dashboard. ',
    //         image: 'innovation',
    //     },
    //     {
    //         title: '<b>Leading NetSecOps</b> solution that enables Secure Network Access, Customer experience, and visibility in a <b>single window.</b>',
    //         image: 'dashboard',
    //     },
    //     {
    //         title: '<b>Integrated Network Security</b> & SD-WAN platform for <b>remote branch offices, WFH users, IoT & cloud access.</b>',
    //         image: 'integration',
    //     },
    //     {
    //         title: 'Resilient and flexible WAN with <b>customised workflow</b> and <b>support.</b>',
    //         image: 'workflow',
    //     },
    //     {
    //         title: '<b>3x more effective in threat hunting</b> through innovations in network anomaly detection.',
    //         image: 'spy',
    //     },
    //     {
    //         title: '<b>Industry-proven value proposition</b> with solid customer retention for more than 6+ years',
    //         image: 'customer-service',
    //     },
    //     {
    //         title: '<b>Cost effective products and solutions</b> w.r.t Pain Points and Use cases',
    //         image: 'payment',
    //     },
    //     {
    //         title: '<b>Durable</b> and Robust <br><b>Hardware</b>',
    //         image: 'wifi-router',
    //     },
    // ];



    // whyEnterprisesChooseCosgrid = [
    //     {
    //         title: 'Boost App Performance',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Challenges%20and%20Needs/Boost%20App%20Performance.webp',
    //         points: ['Unbreakable App Sessions', 'Multi-WAN link Aggregation', 'Application Acceleration'],
    //     },
    //     {
    //         title: 'Capex & Opex down by 50%',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets/images/home/home-s2-img-02.png',
    //         points: ['Migrate from MPLS to Internet', '10X more Bandwidth', 'Flexible HW options'],
    //     },
    //     {
    //         title: 'Secure Apps & Users',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Challenges%20and%20Needs/Secure%20Apps%20&%20Users.png',
    //         points: ['Secure & Hardened Edge', 'Resilient Encrypted Tunnels', 'Automated Access Controls'],
    //     },
    //     {
    //         title: 'Simplify Operations',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Challenges%20and%20Needs/SIMPLIFY%20OPERATIONS.webp',
    //         points: [
    //             'One tab for complete management',
    //             'Zero-touch with real-time Alarms',
    //             'Scale using Network automation',
    //         ],
    //     },
    // ];

    // challenge = USECASES.map((useCases) => ({ ...useCases, link: useCases.link }));
    // approach = [
    //     {
    //         title: 'Networking, Security & Cloud',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets/images/home/home-s4-img-04.svg',
    //         text: 'Integrated, optimized and an effective solution at the intersection',
    //     },
    //     {
    //         title: 'High Performant Resilient WAN',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets/images/home/home-s4-img-02.svg',
    //         text: 'Best-in-class patented technology that leverages multiple WAN links, access types',
    //     },
    //     {
    //         title: 'Network-as-a-Service',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets/images/home/home-s4-img-03.svg',
    //         text: 'Aligned with your enterprise business model and enabled by Software defined approach',
    //     },
    //     {
    //         title: 'Extendable Platform',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets/images/home/home-s4-img-01.svg',
    //         text: 'Standards based with open APIs and hence no vendor lock-in. Easy to integrate',
    //     },
    // ];

    // associations: IAssocicationItem[] = [
    //     {
    //         title: 'DSCI',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/kanpur.png',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Associated%20with/IIT%20Kanpur.webp',
    //         // width: '100%'
    //     },
    //     {
    //         title: 'NCOE',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/ncoe_cut.png',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Associated%20with/National%20Centre%20of%20Excellence.webp',
    //     },
    //     {
    //         title: 'MINISTRY',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/ass6.png',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Associated%20with/MINISTRY%20OF%20COMMUNICATIONS.webp',
    //     },
    //     {
    //         title: 'TCOE',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Associated%20with/TCOE%20India.webp',
    //     },
    //     {
    //         title: 'TEPC',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Associated%20with/TEPC.webp',
    //     },
    //     // {
    //     //     title: 'Nasscom',
    //     //     imgURL: 'https://cdn.cosgrid.com/website/assets/blog_images/new_page/nasscom_logo_svg_1.webp',
    //     // },
    //     // {
    //     //     title: 'Microsoft',
    //     //     imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/microsoftImage.png',
    //     // },
    //     // {
    //     //     title: 'Intel',
    //     //     imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/intelImage.png',
    //     // },
    //     // {
    //     //     title: 'Department of TeleCommunications',
    //     //     imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/dotc.png',
    //     // },
    //     // {
    //     //   title: '10000 Start-ups',
    //     //   imgURL:
    //     //     'https://cdn.cosgrid.com/website/assets/images/association/association-10000_startups.png',
    //     // },
    //     // {
    //     //   title: 'Amrita TBI',
    //     //   imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/association-amrita_tbi.png',
    //     // },
    //     // {
    //     //   title: 'Jio Gennext',
    //     //   imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/association-gennext.png',
    //     // },
    // ];
    // industry_partners = [
    //     {
    //         id: 1,
    //         title: 'Microsoft',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/microsoftImage.png',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/Microsoft.webp',
    //         width: 'w-40',
    //     },
    //     {
    //         id: 2,
    //         title: 'Intel',
    //         // imgURL: 'https://cdn.cosgrid.com/website/assets/images/association/intelImage.png',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/intel.webp',
    //         width: 'w-30',
    //     },
    //     {
    //         id: 3,
    //         title: 'Google Cloud',
    //         imgURL: 'https://cdn.cosgrid.com/website/assets3/Home/Customers/Google-cloud.png',
    //         width: 'w-50',
    //     },
    // ];


    numbers = Array.from({ length: 3 }, (_, i) => i);

    // @ViewChild('videoScroll') scrollableDiv: ElementRef;

    // scrollLeft() {
    //     this.scrollableDiv.nativeElement.scrollLeft -= 450;
    // }

    // scrollRight() {
    //     this.scrollableDiv.nativeElement.scrollLeft += 450;
    // }

    constructor(
        private router: Router,
        private emitter: MyEmitterService,
        private apollo: Apollo,
        private datepipe: DatePipe,
        private titleService: Title,
        private metaService: Meta,
        private fetchMetaService: FetchMetaService,
        public sanitizer: DomSanitizer,
    ) { }

    routeToPage(page) {
        // console.log(page);
        this.router.navigate(page);
    }

    routeToCase(item) {
        // console.log(item);
        this.router.navigateByUrl(`/resources/sd-wanusecases#${item.id}`);
    }
    ngOnInit(): void {
        if (this.fetchMetaService.getData() == false) {
            let metatags = [];
            this.apollo
                .watchQuery({
                    query: META_QUERY,
                })
                .valueChanges
                .pipe(takeUntil(this.destroy$))
                .subscribe((result) => {
                    console.log("result in home page", result)
                    let meta = [];
                    meta = (result.data as any).metaTags.data.map((metaTag) => ({
                        ...metaTag,
                    }));
                    for (let i = 0; i < meta.length; i++) {
                        metatags[meta[i].attributes.URL_ID] = meta[i];
                    }
                    // console.log("Meta Tags : ", this.metatags);
                    this.fetchMetaService.storeData(metatags);
                    let metaTagsData = this.fetchMetaService.getData();
                    // console.log("Meta Tags ", metaTagsData);

                    this.titleService.setTitle(metaTagsData['home'].attributes.title);
                    this.metaService.updateTag({
                        name: 'description',
                        content: metaTagsData['home'].attributes.description,
                    });
                    this.metaService.updateTag({
                        name: 'keywords',
                        content: metaTagsData['home'].attributes.keywords,
                    });
                });
        } else {
            console.log("Data Alreday Fetched");
            const data = this.fetchMetaService.getData();
            this.titleService.setTitle(data['home'].attributes.title);
            this.metaService.updateTag({
                name: 'description',
                content: data['home'].attributes.description,
            });
            this.metaService.updateTag({
                name: 'keywords',
                content: data['home'].attributes.keywords,
            });
        }
        this.blogs = this.apollo
            .watchQuery({
                query: BLOG_QUERY,
            })
            .valueChanges
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                this.blogs = (result.data as any).blogs.data.map((blog) => ({
                    ...blog,
                    image: blog.attributes.Title_image.data.attributes.url,
                }));

                for (let i = 0; i < 3; i++) {
                    let myDate = new Date(this.blogs[i].attributes.Dateofpublish);
                    this.blogdata[i].date = this.datepipe.transform(myDate, 'dd-MMM-yyyy');
                }
            });
    }

    routeToblog(blog) {
        this.router.navigateByUrl(`/resources/blogs/${blog.attributes.idUrl}`, {
            state: {
                blog,
            },
        });
    }
    ngOnDestroy(): void {
        this.destroy$.next()
        this.destroy$.complete()
    }
}
