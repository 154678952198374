<div *ngIf="!fullyLoaded" class="position-fixed d-flex justify-content-center align-items-center z-3 top-0 start-0 h-100 w-100 bg-clr-white">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
</div>


<div style="padding: 0; overflow: hidden;" *ngIf="fullyLoaded">
    <ng-container *ngIf="screenWidthDetermined">
        <ng-container *ngIf="screenWidth > 992; else mobileTemplate">
          <app-navbar></app-navbar>
        </ng-container>
      
        <ng-template #mobileTemplate>
          <mobile-app-navbar></mobile-app-navbar>
        </ng-template>
      </ng-container>
    <main>
        <div>
            <router-outlet (activate)="onActivate($event)"> </router-outlet>
        </div>
    </main>
    <ng-container *ngIf="router !== '/resources/sd-wan' && router !== '/resources/ztna'">
        <ng-container *ngIf="screenWidth > 900; else elseTemplate">
            <app-footer></app-footer>
        </ng-container>
    </ng-container>
    <ng-template #elseTemplate>
        <app-mobilefooter></app-mobilefooter>
    </ng-template>
    <app-cookie-consent></app-cookie-consent>
</div>
